import * as R from 'ramda'
import { connect } from 'react-redux'

import * as api from '@rushplay/api-client'
import * as notifications from '@rushplay/notifications'
import * as jurisdiction from '@rushplay/compliance/jurisdiction'

import * as player from '../player'

import { Receipts as Component } from './receipts'

const Receipts = R.compose(
  connect(
    (state, props) => ({
      currentRealBalance: player.getMoneyBalanceCents(state.player),
      language: player.getLanguage(state.player),
      loading: player.getCurrentReceiptPage(state.player) !== props.currentPage,
      receipts: player.getReceipts(state.player),
      showTotalBalanceTransactions: jurisdiction.isBalanceTotalsVisible(
        state.jurisdiction
      ),
      totalDepositCents: player.getTotalDepositsCents(state.player),
      totalPages: player.getTotalReceiptPages(state.player),
      totalWithdrawCents: player.getTotalWithdrawsCents(state.player),
    }),
    dispatch => {
      const fetchReceiptsConfig = {
        success: res => player.initReceipts(res.value),
        failure: res =>
          notifications.add({
            message: `errors.${res.value.message || 'general.unknown'}`,
            level: 'error',
          }),
        version: 1,
      }

      return {
        onFetch: () =>
          dispatch([
            api.fetchTotalDepositsCents({
              success: res => player.updateDepositTotals(res.value),
              version: 2,
            }),
            api.fetchReceipts(1, fetchReceiptsConfig),
            api.fetchTransactionTotals({
              success: res => player.updateBalanceTotals(res.value),
              failure: () =>
                notifications.add({
                  message: 'errors.receipts.failed-loading',
                  level: 'error',
                }),
              version: 1,
            }),
          ]),
        onNav: page => dispatch(api.fetchReceipts(page, fetchReceiptsConfig)),
      }
    }
  )
)(Component)

// For @loadable/components
export default Receipts
