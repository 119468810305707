import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Herz from '@rushplay/herz'

import Receipts from './receipts'
import { QueryDrawer } from './query-drawer'
import { useMenuQueries } from './use-menu-queries'

export function GamblingHistoryDrawer() {
  const history = ReactRouter.useHistory()
  const translate = Herz.I18n.useTranslate()
  const { authenticated } = Herz.Auth.useSession()

  const { menuQuery } = useMenuQueries()

  if (!authenticated) {
    return null
  }

  return (
    <QueryDrawer
      activeQueryName="transaction-history"
      title={translate('transaction-history.title')}
      onSecondaryAction={() => history.push(`?${menuQuery}`)}
      contentPadding="0px"
    >
      <Receipts />
    </QueryDrawer>
  )
}

export default GamblingHistoryDrawer

Herz.I18n.Loader.preload(['transaction-history.title'], GamblingHistoryDrawer)
