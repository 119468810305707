import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as Url from 'url'
import PropTypes from 'prop-types'
import { AnimatePresence, motion } from 'framer-motion'

import * as Api from '@rushplay/api-client'
import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import * as Notifications from '@rushplay/notifications'
import styled from '@emotion/styled'

import * as Configuration from '../configuration'
import * as Constants from '../constants'
import * as Icons from '../icons'
import * as Player from '../player'
import { Amount } from '../amount'
import { HtmlContent } from '../html-content'
import { mapIndexed } from '../map-indexed'
import { useMenuQueries } from '../use-menu-queries'
import { useServerConfiguration } from '../server-configuration'

const ReceiptWrapper = styled('div', {
  shouldForwardProp: Common.noneOf(['isOpen']),
})`
  cursor: pointer;
  padding: 8px 24px;

  ${props => ({
    color: props.isOpen ? '#fff' : 'darken-gray',
    backgroundColor: props.isOpen ? '#2a3149' : 'transparent',
  })}

  border-top: 1px solid #2a3149;
  &:last-child {
    border-bottom: 1px solid #2a3149;
  }
  @media (min-width: 1280px) {
    &:hover {
      color: #fff;
      background-color: #2a3149;
    }
  }
`

const DepositTransactionType = 'DepositTransaction'

function Receipt(props) {
  const [isOpen, setIsOpen] = React.useState(false)
  const transactionType = Herz.Utils.Strings.toKebabCase(props.transactionType)
  const translate = Herz.I18n.useTranslate(
    () => [`receipt.transaction.${transactionType}`],
    [transactionType]
  )

  const { locale } = useServerConfiguration()
  const date = new Date(props.date)
  const formattedDate = date.toLocaleDateString(locale.slug)
  const formattedTime = date.toLocaleTimeString(locale.slug)

  function handleToggleIsOpen() {
    setIsOpen(curr => !curr)
  }

  return (
    <React.Fragment>
      <ReceiptWrapper isOpen={isOpen} onClick={handleToggleIsOpen}>
        <Common.Box display="flex" justifyContent="space-between">
          <Common.Box
            display="flex"
            fontSize={2}
            fontWeight="700"
            fontFamily="head"
            color="static-white"
            lineHeight="1.5"
            alignItems="center"
          >
            <Common.Box fontSize={4} display="flex" alignItems="center">
              <Icons.Okay />
            </Common.Box>
            <Common.Box pb={0} minWidth="110px">
              {translate(`receipt.transaction.${transactionType}`)}{' '}
            </Common.Box>
          </Common.Box>
          <Common.Box
            display="flex"
            flexDirection="column"
            flex="1"
            alignItems="center"
            justifyContent="center"
            fontSize={1}
            color="#CDCED0"
          >
            <Common.Box fontWeight="600" lineHeight="18px">
              {formattedDate}
            </Common.Box>
            <Common.Box lineHeight="18px">{formattedTime}</Common.Box>
          </Common.Box>
          <Common.Box display="flex" fontSize={1}>
            <Common.Box
              color="#B2C2EE"
              display="inline-block"
              fontSize={5}
              data-testid={
                isOpen ? 'receipt.expand-less' : 'receipt.expand-more'
              }
            >
              {isOpen ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
            </Common.Box>
          </Common.Box>
        </Common.Box>
      </ReceiptWrapper>
      {isOpen && (
        <Common.Box
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          pt={0}
          px={2}
          backgroundColor="rgba(0, 0, 0, 0)"
          color="static-white"
          fontSize={2}
          alignItems="center"
        >
          <Common.Box py={0}>
            {translate(
              props.type === DepositTransactionType
                ? 'receipt.cell.game-coins'
                : 'receipt.cell.sweeps-coins'
            )}
          </Common.Box>
          <Common.Box py={0} textAlign="right" fontWeight="700">
            <Amount currency={props.currency} decimals="show">
              {props.amount}
            </Amount>
          </Common.Box>
          {props.type === DepositTransactionType ? (
            <React.Fragment>
              <Common.Box py={0}>
                {translate('receipt.cell.sweeps-coins')}
              </Common.Box>
              <Common.Box
                py={0}
                textAlign="right"
                fontWeight="700"
                display="flex"
                justifyContent="flex-end"
              >
                <Common.Box mr="2px">
                  {translate('receipt.cell.sweeps-coins.free')}
                </Common.Box>
                <Amount currency="SCC" decimals="show">
                  {props.sweepstakesCoinsCents}
                </Amount>
              </Common.Box>
            </React.Fragment>
          ) : null}
          <Common.Box py={0}>{translate('receipt.cell.amount-usd')}</Common.Box>
          <Common.Box py={0} textAlign="right" fontWeight="700">
            <Amount currency={props.originalCurrency} decimals="show">
              {props.originalAmountCents}
            </Amount>
          </Common.Box>
          <Common.Box py={0}>{translate('receipt.cell.status')}</Common.Box>
          <Common.Box py={0} textAlign="right" color="#54EBB9" fontWeight="700">
            {translate('receipt.status.accepted')}
          </Common.Box>
        </Common.Box>
      )}
    </React.Fragment>
  )
}

Herz.I18n.Loader.preload(
  [
    'receipt.fee-info',
    'receipts.information',
    'receipt.status.accepted',
    'receipt.cell.game-coins',
    'receipt.cell.amount-usd',
    'receipt.cell.status',
    'receipt.cell.sweeps-coins',
    'receipt.cell.sweeps-coins.free',
  ],
  Receipt
)

Receipt.propTypes = {
  amount: PropTypes.number,
  feeAmountCents: PropTypes.number,
  language: PropTypes.string,
  date: PropTypes.string,
  currency: PropTypes.oneOf(Constants.CurrencyCodes),
  originalAmountCents: PropTypes.number,
  originalCurrency: PropTypes.oneOf(Constants.CurrencyCodes),
  provider: PropTypes.string,
  sweepstakesCoinsCents: PropTypes.number,
  transactionType: PropTypes.string,
  type: PropTypes.string,
}

function sanitizeRedemptionAmount(amount) {
  if (!amount || typeof amount !== 'string') {
    return ''
  }
  const regex = /[^\d.-]/g
  const sanitized = amount.replace(regex, '')

  return sanitized
}

function PendingRedemption(props) {
  const translate = Herz.I18n.useTranslate()
  const { locale } = useServerConfiguration()
  const [isOpen, setIsOpen] = React.useState(false)

  const date = new Date(props.created)
  const formattedDate = date.toLocaleDateString(locale.slug)
  const formattedTime = date.toLocaleTimeString(locale.slug)
  return (
    <Common.Box
      cursor="pointer"
      border="1px solid #2A3149"
      borderRadius="6px"
      p={0}
      mb={0}
      width="100%"
      backgroundColor="#0C132B"
      onClick={() => setIsOpen(curr => !curr)}
    >
      <Common.Box display="flex" justifyContent="space-between">
        <Common.Box
          display="flex"
          fontSize={2}
          fontWeight="700"
          fontFamily="head"
          color="static-white"
          lineHeight="1.5"
          alignItems="center"
          flex="1"
        >
          <Common.Box
            width="45px"
            height="30px"
            backgroundImage="url('/images/pending-redemption.svg')"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
          />
          <Common.Box ml={0}>
            {translate('receipts.pending-redemption')}
          </Common.Box>
        </Common.Box>
        <Common.Box
          display="flex"
          flexDirection="column"
          flex="2"
          alignItems="center"
          justifyContent="center"
          fontSize={1}
          color="#CDCED0"
        >
          <Common.Box fontWeight="600" lineHeight="18px">
            {formattedDate}
          </Common.Box>
          <Common.Box lineHeight="18px">{formattedTime}</Common.Box>
        </Common.Box>
        <Common.Box display="flex" fontSize={1} alignItems="center">
          <Common.Box
            color="#B2C2EE"
            display="inline-block"
            fontSize={5}
            data-testid={isOpen ? 'receipt.expand-less' : 'receipt.expand-more'}
          >
            {isOpen ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
          </Common.Box>
        </Common.Box>
      </Common.Box>
      <Common.Box
        display={isOpen ? 'grid' : 'none'}
        gridTemplateColumns="repeat(2, 1fr)"
        pt={1}
        ml="37px"
        fontSize={2}
        overflow="hidden"
      >
        <Common.Box>Sweeps Coins:</Common.Box>
        <Common.Box px={0}>{sanitizeRedemptionAmount(props.amount)}</Common.Box>
      </Common.Box>
    </Common.Box>
  )
}

PendingRedemption.propTypes = {
  amount: PropTypes.string,
  created: PropTypes.string,
}

Herz.I18n.Loader.preload(['receipts.pending-redemption'], PendingRedemption)

function getPayerConfig(state) {
  return {
    host: Configuration.getPayerUrl(state.configuration),
    userId: Player.getUsername(state.player),
  }
}

function PendingRedemptions() {
  const { token } = Herz.Auth.useSession()
  const config = ReactRedux.useSelector(getPayerConfig)
  const [
    isPendingTransactionsLoading,
    setIsPendingTransactionsLoading,
  ] = React.useState(true)
  const [pendingTransactions, setPendingTransactions] = React.useState([])

  const pendingTransactionsUrl = Url.format({
    pathname: '/api/pending-transactions',
    query: {
      token,
      user_id: config.userId,
    },
  })

  React.useEffect(() => {
    if (config.userId || token) {
      const controller = new AbortController()

      fetch(`${config.host}${pendingTransactionsUrl}`, { method: 'GET' })
        .then(res => res.json())
        .then(body => {
          const data = body?.data || []
          setPendingTransactions(data)
          return setIsPendingTransactionsLoading(false)
        })
        .catch(() => {
          return setIsPendingTransactionsLoading(false)
        })

      const id = setTimeout(() => controller.abort(), 5000)

      return () => {
        clearTimeout(id)
        controller.abort()
      }
    }
  }, [config.userId, token])

  if (isPendingTransactionsLoading || pendingTransactions.length === 0) {
    return null
  }

  return (
    <Common.Box p={1}>
      {pendingTransactions.map(transaction => (
        <PendingRedemption key={transaction.transactionId} {...transaction} />
      ))}
    </Common.Box>
  )
}

export function Receipts(props) {
  const dispatch = ReactRedux.useDispatch()
  const translate = Herz.I18n.useTranslate()
  const currentPage = ReactRedux.useSelector(state =>
    Player.getCurrentReceiptPage(state.player)
  )
  const totalPages = ReactRedux.useSelector(state =>
    Player.getTotalReceiptPages(state.player)
  )
  const { depositQuery } = useMenuQueries()

  React.useEffect(() => {
    props.onFetch()
  }, [])

  function handleLoadMore() {
    if (totalPages <= currentPage) {
      return
    }

    dispatch(
      Api.fetchReceipts(currentPage + 1, {
        success: res => Player.updateReceipts(res.value),
        failure: res =>
          Notifications.add({
            message: `errors.${res.value.message || 'general.unknown'}`,
            level: 'error',
          }),
        version: 1,
      })
    )
  }

  if (props.receipts.length === 0) {
    return (
      <Common.Box>
        <PendingRedemptions />
        <Common.Box
          px={1}
          py={2}
          width="100%"
          maxWidth="1000px"
          color="menu-item"
          textAlign="center"
        >
          {translate('receipts.empty-history')}
          <Common.Space pt={0}>
            <ReactRouter.Link to={`?${depositQuery}`}>
              <Common.Text textDecoration="underline" fontWeight="800">
                {translate('receipts.make-history')}
              </Common.Text>
            </ReactRouter.Link>
          </Common.Space>
        </Common.Box>
      </Common.Box>
    )
  }

  return (
    <Common.Box display="flex" width="100%" justifyContent="center">
      <Common.Box
        display="flex"
        flexDirection="column"
        borderRadius={1}
        boxShadow={[null, null, 0]}
        maxWidth="1000px"
        mx="-5px"
        width="100%"
        data-testid="receipts.grid"
        mb={2}
      >
        <Common.Box
          px={3}
          py={2}
          textAlign="center"
          fontSize={2}
          color="#B2C2EE"
          lineHeight="16px"
        >
          <HtmlContent
            html={{
              __html: translate('receipts.info-text'),
            }}
          />
        </Common.Box>
        <PendingRedemptions />
        <AnimatePresence>
          {mapIndexed(
            (receipt, index) => (
              <Common.Box
                key={`${receipt.transactionId}_${receipt.createdAt}`}
                data-testid="receipts.grid-item"
              >
                <motion.div
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{
                    scale: 0.8,
                    opacity: 0,
                    transition: { duration: 0 },
                  }}
                  initial={{ scale: 0.8, opacity: 0 }}
                  key={receipt.transactionId + receipt.createdAt + index}
                  style={{ width: '100%' }}
                  transition={{
                    duration: 0.25,
                    delay: (index % 10) * 0.04,
                  }}
                >
                  <Receipt
                    amount={receipt.totalAmountCents}
                    feeAmountCents={receipt.feeAmountCents}
                    currency={receipt.currency}
                    date={receipt.createdAt}
                    key={receipt.transactionId}
                    sweepstakesCoinsCents={receipt.sweepstakesCoinsCents}
                    type={receipt.type}
                    provider={
                      R.equals(receipt.subProvider, 'Trustly')
                        ? receipt.subProvider
                        : 'legendz.com'
                    }
                    transactionType={receipt.type}
                    {...receipt}
                  />
                </motion.div>
              </Common.Box>
            ),
            props.receipts
          )}
        </AnimatePresence>
        {currentPage < totalPages ? (
          <Common.Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={1}
          >
            <Common.Box
              padding="10px 20px"
              border=" 1px solid #919294"
              borderRadius="6px"
              cursor="pointer"
              onClick={handleLoadMore}
            >
              {translate('receipts.show-more')}
            </Common.Box>
          </Common.Box>
        ) : null}
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    'receipts.title',
    'receipts.no-transactions',
    'receipts.info-text',
    'receipts.empty-history',
    'receipts.make-history',
    'receipts.show-more',
  ],
  Receipts
)

Receipts.propTypes = {
  currentPage: 1,
}

Receipts.propTypes = {
  currency: PropTypes.oneOf(Constants.CurrencyCodes),
  currentRealBalance: PropTypes.number,
  currentPage: PropTypes.number,
  loading: PropTypes.bool,
  receipts: PropTypes.array,
  showTotalBalanceTransactions: PropTypes.bool,
  totalDepositCents: PropTypes.number,
  totalWithdrawCents: PropTypes.number,
  totalPages: PropTypes.number,
  onFetch: PropTypes.func,
  onNav: PropTypes.func,
}
